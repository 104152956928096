import React, { useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import styled from '@emotion/styled'
import { getCookie } from 'cookies-next'
import { SwiperSlide } from 'swiper/react'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import * as ChannelService from '@channel.io/channel-web-sdk-loader'

import { getFrontBanner, getCommonSelectcity, getFrontMusteatMain } from 'api'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Barogage from 'components/Barogage'
import Carousel from 'components/common/Carousel'
import Popup from 'components/MainSection/Popup'

import MustgoBanner from 'components/MainSection/MustgoBanner'
import MusteatBanner from 'components/MainSection/MusteatBanner'
import BuyNow from 'components/common/icon/BuyNow'
import Reservation from 'components/common/icon/Reservation'
import CompanyInfo from 'components/MainSection/CompanyInfo'
import { BLUR, HOST, TAIL } from 'constants/image'
import Terms from 'components/common/Terms'
import { DEFAULT_LANG } from 'constants/lang'
import { checkKTC, switchWhitelabel } from 'lib'
import { KOREA_TRAVEL_CARD_LINK } from 'constants/ktc'

const Main = ({
  initialLang,
  initialBanner,
  initialCities,
  initialMusteat,
  channel,
}) => {
  const { t } = useTranslation()

  const lang = initialLang
  const {
    logo,
    banner,
    cat2: initialCat2,
    s_commercial_area_id,
    mustgo,
  } = switchWhitelabel(channel, lang)

  const [cat1, setCat1] = useState(initialCat2)
  const [cat2, setCat2] = useState(s_commercial_area_id)
  const [cities, setCities] = useState(initialCities)
  const [area, setArea] = useState(
    cities.find((e) => e.id === initialCat2).commercial_areas
  )
  const [musteat, setMusteat] = useState(initialMusteat)
  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    if (!didMount) return
    callGetFrontMusteatMain(cat2)
    callGetCommonSelectcity()
  }, [lang])

  useEffect(() => {
    if (!didMount) return
    setCat2(cities.find((e) => e.id === cat1).commercial_areas[0].id)
    setArea(cities.find((e) => e.id === cat1).commercial_areas)
    callGetFrontMusteatMain(
      cities.find((e) => e.id === cat1).commercial_areas[0].id
    )
  }, [cat1])

  useEffect(() => {
    if (!didMount) return
    callGetFrontMusteatMain(cat2)
  }, [cat2])

  useEffect(() => {
    setDidMount(true)
  }, [])

  useEffect(() => {
    if (!didMount) return
    ChannelService.loadScript()
    ChannelService.boot({
      pluginKey: process.env.NEXT_PUBLIC_CHANNELSERVICE_PLUGINKEY,
    })
  }, [didMount])

  // API 호출함수
  const callGetCommonSelectcity = async () => {
    await getCommonSelectcity({
      s_lang: initialLang,
      s_channel: channel,
    }).then((res) => {
      const arr = [...Object.values(res)]
      setCities(arr)
      setArea(arr.find((e) => e.id === cat1).commercial_areas)
    })
  }

  const callGetFrontMusteatMain = async (param) => {
    await getFrontMusteatMain({
      s_commercial_area_id: param,
      s_channel: channel,
      s_lang: lang,
    }).then((res) => setMusteat(res))
  }

  // 렌더링 컨텐츠 선언
  const city =
    initialCat2 === 2 ? cities : [cities.find((e) => e.id === initialCat2)]
  const citySlide = city.map(
    (item) =>
      item.cnt > 0 && (
        <SwiperSlide
          key={item.id}
          onClick={() => setCat1(item.id)}
          className="w-140"
        >
          <MustgoBanner
            name={item.translate_name2 ? item.translate_name2 : item.name2}
            cnt={item.cnt}
            checked={cat1 === item.id}
            t={t}
            contents={
              <Image
                src={`${HOST}${item.location_file_path}${TAIL}`}
                alt=""
                width={0}
                height={0}
                sizes="100%"
                blurDataURL={BLUR}
                style={{
                  borderRadius: 5,
                  width: '100%',
                  height: '100%',
                }}
              />
            }
          />
        </SwiperSlide>
      )
  )

  const areaSlide = area.map(
    (item) =>
      item.cnt > 0 && (
        <SwiperSlide
          key={item.id}
          onClick={() => setCat2(item.id)}
          className="w-140"
        >
          <MustgoBanner
            name={item.translate_commercial_area_name}
            cnt={item.cnt}
            checked={cat2 === item.id}
            t={t}
            contents={
              <Image
                src={
                  item.commercial_area_file_path
                    ? `${HOST}${item.commercial_area_file_path}${TAIL}`
                    : BLUR
                }
                alt=""
                width={140}
                height={120}
                blurDataURL={BLUR}
                style={{
                  borderRadius: 5,
                  width: '100%',
                  height: '100%',
                }}
              />
            }
          />
        </SwiperSlide>
      )
  )

  const musteatsSlide = musteat.must_eats.map(
    (item) =>
      item.must_eat_count && (
        <SwiperSlide key={item.id} className="w-140">
          <Link
            href={`/must-eat?s_commercial_area_id=${cat2}&s_must_eat_id=${item.id}`}
          >
            <MustgoBanner
              name={item.translate_must_eat_name}
              cnt={parseInt(item.must_eat_count).toLocaleString('ko-kr')}
              t={t}
              contents={
                <Image
                  src={
                    item.must_eat_file_path
                      ? `${HOST}${item.must_eat_file_path}${TAIL}`
                      : BLUR
                  }
                  alt=""
                  width={140}
                  height={120}
                  blurDataURL={BLUR}
                  style={{
                    borderRadius: 5,
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
            />
          </Link>
        </SwiperSlide>
      )
  )

  const carouselItems = [
    mustgo && {
      label: `${t('place')}${
        cities[0].translate_name1 ? cities[0].translate_name1 : cities[0].name1
      }`,
      contents: citySlide,
      more: `/select-city?s_city=${cat1}&s_commercial_area_id=${cat2}`,
    },
    mustgo && {
      label: `${t('place')}${
        cities.find((e) => e.id === cat1).translate_name2
          ? cities.find((e) => e.id === cat1).translate_name2
          : cities.find((e) => e.id === cat1).name2
      }`,
      description: cities.find((e) => e.id === cat1).translate_description,
      contents: areaSlide,
      more: `/select-city?s_city=${cat1}&s_commercial_area_id=${cat2}`,
    },
    {
      label: `${t('must-eat')}${
        area.find((e) => e.cnt > 0 && e.id === cat2)
          ?.translate_commercial_area_name ?? ''
      }`,
      contents: musteatsSlide,
    },
  ]

  // 렌더링 데이터 편집
  const generateMusteat = () => {
    let arr = []
    musteat.must_eats.map((item) => {
      return arr.push({
        id: item.id,
      })
    })

    musteat.must_eats.map((item) => {
      const index = arr.indexOf(arr.find((e) => e.id === item.id))
      if (index === -1) return null
      arr[index] = { ...item }
      return null
    })

    Object.keys(musteat.data).map((item) => {
      const index = arr.indexOf(arr.find((e) => e.id === parseInt(item)))
      if (index === -1) return null
      const data = musteat.data[item]
      arr[index] = { ...arr[index], ...data }
    })

    return arr
  }

  return (
    <>
      <Header logo={logo} />
      <Popup data={initialBanner.data} t={t} />
      <Container>
        {/* 메인배너 or KTC */}
        <div className="px-16" style={{ marginTop: 16 }}>
          {checkKTC(channel) ? (
            <Link href={'/theme/215'}>
              <Image
                src={`${HOST}${banner}${TAIL}`}
                alt=""
                width={448}
                height={120}
                className="main-banner"
                style={{ width: '100%' }}
              />
            </Link>
          ) : (
            <Image
              src={`${HOST}${banner}${TAIL}`}
              alt=""
              width={448}
              height={120}
              className="main-banner"
              style={{ width: '100%' }}
            />
          )}
        </div>

        {/* 배너 */}
        {channel === 'redtable' && (
          <div className="px-16">
            <Link href="/theme/220">
              <Image
                src={`${HOST}/asset/banner/lotte/event/lottedutyfree_240712_${lang}.png${TAIL}`}
                alt=""
                width={448}
                height={120}
                className="main-banner"
                style={{ width: '100%' }}
              />
            </Link>
          </div>
        )}

        {/* 투어 */}
        {channel === 'redtable' && (
          <div className="px-16">
            <Link href="/tour">
              <Image
                src={`${HOST}/asset/banner/v2/tour_${lang}.jpg${TAIL}`}
                alt=""
                width={448}
                height={120}
                className="main-banner"
                style={{ width: '100%' }}
              />
            </Link>
          </div>
        )}

        {/* 바로가게  */}
        {checkKTC(channel) && <Barogage lang={lang} t={t} />}

        {carouselItems.map((item, index) => (
          <Carousel
            key={index}
            label={item.label}
            description={item.description}
            contents={item.contents}
            more={item.more}
            option={item.option}
          />
        ))}

        <Horizen>
          <ul>
            <li>
              <BuyNow />
              <p>{t('main_buynow_ico_txt')}</p>
            </li>
            <li>
              <Reservation />
              <p>{t('main_reservation_ico_txt')}</p>
            </li>
          </ul>
        </Horizen>

        {generateMusteat().map(
          (item) =>
            item.status === 'normal' &&
            item.stores.length > 0 && (
              <Carousel
                key={item.id}
                label={`#${item.translate_commercial_area_name} #${
                  item.translate_must_eat_name ?? item.must_eat_name
                }`}
                more={`/must-eat?s_commercial_area_id=${cat2}&s_must_eat_id=${item.id}`}
                contents={item.stores.map((store) => (
                  <SwiperSlide key={store.store_id} className="w-140">
                    <MusteatBanner
                      data={store}
                      t={t}
                      contents={
                        <Image
                          src={
                            store.product_image
                              ? `${HOST}${store.product_image}${TAIL}`
                              : store.store_image
                              ? `${HOST}${store.store_image}${TAIL}`
                              : '/blank.jpg'
                          }
                          alt=""
                          width={144}
                          height={110}
                          blurDataURL={BLUR}
                          style={{
                            width: '100%',
                            height: 110,
                            objectFit: 'cover',
                            borderRadius: 5,
                          }}
                        />
                      }
                    />
                  </SwiperSlide>
                ))}
              />
            )
        )}

        <Terms t={t} />
        <CompanyInfo t={t} channel={channel} />
      </Container>
      <Footer t={t} />
    </>
  )
}

export default Main

export const getServerSideProps = async (ctx) => {
  // const { req } = ctx
  // const subdomain = req ? req.headers.host.split('.')[0] : 'redtable'
  // const channel = switchChannel(subdomain)
  const channel = getCookie('channel', ctx)

  const initialLang =
    ctx.locale === 'zh-cn' ? 'zh-Hans' : getCookie('lang', ctx) || DEFAULT_LANG

  const initialBanner = await getFrontBanner(
    {
      s_channel: channel,
      s_lang: initialLang,
    },
    'server'
  )

  const cities = await getCommonSelectcity(
    {
      s_lang: initialLang,
      s_channel: channel,
    },
    'server'
  )

  const initialMusteat = await getFrontMusteatMain(
    {
      s_commercial_area_id: switchWhitelabel(channel).s_commercial_area_id,
      s_channel: channel,
      s_lang: initialLang,
    },
    'server'
  )

  return {
    props: {
      initialLang,
      initialBanner: initialBanner.data,
      initialCities: [...Object.values(cities)],
      initialMusteat,
      ...(await serverSideTranslations(ctx.locale, ['common', 'product'])),
    },
  }
}

const Container = styled.main`
  padding-bottom: 72px;

  .main-banner {
    margin-bottom: 8px;
    border-radius: 5px;
  }

  .px-16 {
    padding: 0 16px;
  }

  .w-140 {
    width: 140px;
  }
`

const Horizen = styled.div`
  width: 100%;
  background-color: #f3f3f3;

  margin: 32px 0 16px;
  padding: 8px 0 8px 16px;
  border-radius: 5px;

  ul {
    display: flex;
    align-items: center;
  }

  li {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: #888;
    margin-left: 4px;
  }
`
