import React, { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import styled from '@emotion/styled'
import { SwiperSlide } from 'swiper/react'
import { Lazy, Autoplay, Pagination, Navigation } from 'swiper'

import {
  theme,
  flex,
  tour,
  instance,
  street,
  best,
  healing,
} from 'constants/barogage'
import Carousel from './common/Carousel'
import FlexBanner from './MainSection/FlexBanner'
import TourBanner from './MainSection/TourBanner'
import InstanceBanner from './MainSection/InstanceBanner'
import StreetBanner from './MainSection/StreetBanner'
import BestBanner from './MainSection/BestBanner'
import HealingBanner from './MainSection/HealingBanner'
import { BLUR, HOST, TAIL } from 'constants/image'

const Barogage = ({ lang, t }) => {
  // 렌더링 컨텐츠 선언
  const thmeSlide = theme.map((item) => {
    const [isLoading, setIsLoading] = useState(false)
    return (
      <SwiperSlide key={item.musteat_id}>
        <Link href={`/must-eat/theme?s_must_eat_id=${item.musteat_id}`}>
          <Image
            src={isLoading ? `${HOST}${item[lang]}${TAIL}` : '/white.png'}
            alt=""
            width={220}
            height={160}
            blurDataURL={BLUR}
            style={{
              width: '100%',
            }}
            onLoadingComplete={() => setIsLoading(true)}
          />
        </Link>
      </SwiperSlide>
    )
  })
  const themeOption = {
    slidesPerView: 2,
    slidesPerGroup: 2,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    pagination: {
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    loop: true,
    modules: [Autoplay, Pagination, Navigation],
  }

  const flexSlide = flex.map((item) => (
    <SwiperSlide key={item.id} style={{ width: 'calc(100% - 32px)' }}>
      <FlexBanner
        id={item.id}
        name={item.name[lang]}
        desc={item.desc[lang]}
        region={item.region[lang]}
        price={item.price}
        t={t}
        contents={
          <Image
            src={`${HOST}${item.image}${TAIL}`}
            alt=""
            width={0}
            height={0}
            sizes={'100%'}
            blurDataURL={BLUR}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 5,
            }}
          />
        }
      />
    </SwiperSlide>
  ))

  const tourSlide = tour.map((item) => (
    <SwiperSlide key={item.must_eat_id} className="w-140">
      <Link
        href={`/must-eat?s_commercial_area_id=${item.com_area_id}&s_must_eat_id=${item.must_eat_id}`}
      >
        <TourBanner
          name={item.name[lang]}
          contents={
            <Image
              src={`${HOST}${item.image}${TAIL}`}
              alt=""
              width={0}
              height={0}
              sizes={'100%'}
              blurDataURL={BLUR}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 5,
              }}
            />
          }
        />
      </Link>
    </SwiperSlide>
  ))

  const instanceSlide = instance.map((item) => (
    <SwiperSlide key={item.id} className="w-140">
      <InstanceBanner
        id={item.id}
        region={item.region[lang]}
        name={item.name[lang]}
        menu={item.menu[lang]}
        price={item.price}
        contents={
          <Image
            src={`${HOST}${item.image}${TAIL}`}
            alt=""
            width={144}
            height={110}
            blurDataURL={BLUR}
            style={{
              width: '100%',
              height: 110,
              objectFit: 'cover',
              borderRadius: 5,
            }}
          />
        }
      />
    </SwiperSlide>
  ))

  const streetSlide = street.map((item) => (
    <SwiperSlide key={item.id} className="w-140">
      <StreetBanner
        id={item.id}
        region={item.region[lang]}
        desc={item.desc[lang]}
        name={item.name[lang]}
        price={item.price}
        award={item.award[lang]}
        contents={
          <Image
            src={`${HOST}${item.image}${TAIL}`}
            alt=""
            width={144}
            height={110}
            blurDataURL={BLUR}
            style={{
              width: '100%',
              height: 110,
              objectFit: 'cover',
              borderRadius: 5,
            }}
          />
        }
      />
    </SwiperSlide>
  ))

  const bestSlide = best.map((item) => (
    <SwiperSlide key={item.id} className="w-140">
      <BestBanner
        id={item.id}
        region={item.region[lang]}
        desc={item.desc[lang]}
        name={item.name[lang]}
        price={item.price}
        contents={
          <Image
            src={`${HOST}${item.image}${TAIL}`}
            alt=""
            width={144}
            height={110}
            blurDataURL={BLUR}
            style={{
              width: '100%',
              height: 110,
              objectFit: 'cover',
              borderRadius: 5,
            }}
          />
        }
      />
    </SwiperSlide>
  ))

  const healingSlide = healing.map((item) => (
    <SwiperSlide key={item.id} className="w-140">
      <HealingBanner
        id={item.id}
        region={item.region[lang]}
        desc={item.desc[lang]}
        name={item.name[lang]}
        price={item.price}
        t={t}
        contents={
          <Image
            src={`${HOST}${item.image}${TAIL}`}
            alt=""
            width={144}
            height={110}
            blurDataURL={BLUR}
            style={{
              width: '100%',
              height: 110,
              objectFit: 'cover',
              borderRadius: 5,
            }}
          />
        }
      />
    </SwiperSlide>
  ))

  const carouselItems = [
    {
      label: t('one_of_those_days'),
      contents: thmeSlide,
      option: themeOption,
      mx: true,
    },
    {
      label: t('fine_time_to_dine'),
      contents: flexSlide,
      option: null,
    },
    {
      label: t('there_is_no_travel_without_food'),
      contents: tourSlide,
      option: null,
    },
    {
      label: t('buy_now_eat_now'),
      contents: instanceSlide,
      option: null,
    },
    {
      label: t('the_local_favorite_restaurants'),
      contents: streetSlide,
      option: null,
    },
    {
      label: t('top_hot_places_in_korea'),
      contents: bestSlide,
      option: null,
    },
    {
      label: t('ready_to_bang_for_the_buck'),
      contents: healingSlide,
      option: null,
    },
  ]

  return (
    <Container>
      {carouselItems.map((item, index) => (
        <Carousel
          key={index}
          label={item.label}
          contents={item.contents}
          option={item.option && item.option}
          mx={item.mx}
        />
      ))}
    </Container>
  )
}

export default Barogage

const Container = styled.section`
  width: 100%;

  .w-140 {
    width: 140px;
  }
`
