import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import styled from '@emotion/styled'
import { Dropdown } from 'antd'
import { Cookies } from 'react-cookie'
import { useSelector } from 'react-redux'

import { switchHeader } from 'lib'
import { languages } from 'constants/lang'
import Icon from './common/Icon'
import Link from 'next/link'
import { IMAGE } from 'constants/image'

const Header = ({ title, style, logo, icon = true }) => {
  const router = useRouter()
  const type = switchHeader(router.asPath)

  const cookies = new Cookies()
  const lang = cookies.get('lang')
  const [newLang, setNewLang] = useState(lang)

  const length = useSelector((state) => state.cart.count)
  const [didMount, setDidMount] = useState(false)

  const untranslate =
    router.asPath.includes('/mypage/term') ||
    router.asPath.includes('/mypage/privacy') ||
    router.asPath.includes('/mypage/refund') ||
    router.asPath.includes('/mypage/point-info')

  const returnImage = (image) => {
    return <Image src={`/icon/${image}.svg`} alt="" width={24} height={24} />
  }

  useEffect(() => {
    if (!didMount) return

    if (!lang) return

    cookies.set('lang', newLang, { path: '/', expires: new Date(2999, 12, 31) })

    router.push(router.asPath, router.asPath, {
      locale: newLang,
    })
  }, [newLang])

  useEffect(() => {
    setDidMount(true)
  }, [])

  // antd-dropdown 규칙 : 싱글파라티를 사용해야함
  const items = languages

  // "뒤로가기" 로직
  // const pushPrevPath = () => {
  //   const prevPath = JSON.parse(sessionStorage.getItem('prevPath'))
  //   let arr = prevPath

  //   if (prevPath.length < 2) return
  //   new Promise(() => {})
  //     .then(arr.pop())
  //     .then(sessionStorage.setItem('prevPath', JSON.stringify(arr)))
  //     .then(router.push(arr[arr.length - 1]))
  //     .then(arr.pop())
  //     .then(sessionStorage.setItem('prevPath', JSON.stringify(arr)))
  // }

  return (
    <Container style={style}>
      {type === 'main' ? (
        <Link href="/" style={{ display: 'flex', alignItems: 'center' }}>
          <Image
            src={logo ? `${IMAGE}${logo}` : '/logo.png'}
            alt=""
            width={136}
            height={24}
            style={{
              width: '50%',
              height: '50%',
            }}
          />
        </Link>
      ) : (
        <DisplayFlex>
          <div onClick={() => history.back()}>
            <Icon image={returnImage('keyboard_backspace')} />
          </div>
          <h2 className="clamp2">{title}</h2>
        </DisplayFlex>
      )}

      {icon && (
        <IconUL>
          {!untranslate && (
            <Dropdown
              menu={{
                items,
                onClick: (e) => setNewLang(e.key),
              }}
              trigger={['click']}
            >
              <li>
                <Icon image={returnImage('translate')} />
              </li>
            </Dropdown>
          )}

          <li>
            {type === 'main' || type === 'mainDepth' ? (
              <Icon image={returnImage('search')} link="/search" />
            ) : (
              <Icon image={returnImage('cottage')} link="/" />
            )}
          </li>
          <li>
            <Icon
              image={returnImage('shopping_cart')}
              link="/cart"
              length={length > 0 && length}
            />
          </li>
        </IconUL>
      )}
    </Container>
  )
}

export default Header

const Container = styled.header`
  width: 100%;
  height: 64px;
  padding: 16px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  box-shadow: 0 3px 6px -6px #666;

  position: sticky;
  top: 0;

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-left: 8px;
    padding-bottom: 2px;
    padding-right: 8px;
  }
`

const IconUL = styled.ul`
  display: flex;
  align-items: center;

  li {
    margin-left: 8px;
  }
`

const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
`
